module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://p532959.webspaceconfig.de/graphql","verbose":false,"debug":{"preview":false,"timeBuildSteps":false,"throwRefetchErrors":false,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"writeQueriesToDisk":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"disableCompatibilityCheck":false},"production":{"hardCacheMediaFiles":false,"allow404Images":false,"allow401Images":false},"develop":{"hardCacheMediaFiles":true,"hardCacheData":true,"nodeUpdateInterval":5000},"schema":{"perPage":10,"requestConcurrency":2,"previewRequestConcurrency":2,"typePrefix":"Wp","timeout":50000,"circularQueryLimit":6,"queryDepth":16},"html":{"useGatsbyImage":true,"gatsbyImageOptions":{},"imageQuality":70,"imageMaxWidth":1024,"createStaticFiles":true,"generateWebpImages":false,"fallbackImageMaxWidth":100},"excludeFieldNames":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"33d75c43067e24e696c6daa131d9c384"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
