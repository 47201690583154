import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

// FIXME: define type ...
type MyContext = any;
export const consentContext = React.createContext<MyContext>(0);

function getConsent(): boolean {
  const consent = Cookies.get('consent');
  return consent !== undefined ? consent === 'true' : false;
}

const Provider = (props) => {
  const [cookieConsent, setConsent] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    const consent = getConsent();
    setConsent(consent);
    setModalVisibility(Cookies.get('consent') === undefined);
  }, []);

  return (
    <consentContext.Provider
      value={{
        cookieConsent,
        modalVisibility,
        toggleModalVisibility: (): void => {
          const cookieConsent = Cookies.get('consent');
          if (cookieConsent === undefined) {
            Cookies.set('consent', 'false', { expires: 365 });
          }
          setModalVisibility(!modalVisibility);
        },
        showModal: (): void => setModalVisibility(true),
        toggleConsent: (): void => {
          setConsent(!cookieConsent);
          Cookies.set('consent', getConsent() ? 'false' : 'true', {
            expires: 365,
          });
        },
        giveFullConsent: (): void => {
          Cookies.set('consent', 'true', { expires: 365 });
          setConsent(true);
        },
      }}
    >
      {props.children}
    </consentContext.Provider>
  );
};

Provider.displayName = 'ConsentProvider';
// eslint-disable-next-line react/display-name
export default ({ element }) => <Provider>{element}</Provider>;
