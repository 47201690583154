exports.components = {
  "component---src-pages-aktuell-speziell-tsx": () => import("./../../../src/pages/aktuell-speziell.tsx" /* webpackChunkName: "component---src-pages-aktuell-speziell-tsx" */),
  "component---src-pages-dank-tsx": () => import("./../../../src/pages/dank.tsx" /* webpackChunkName: "component---src-pages-dank-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-der-stifter-tsx": () => import("./../../../src/pages/der-stifter.tsx" /* webpackChunkName: "component---src-pages-der-stifter-tsx" */),
  "component---src-pages-die-stiftung-tsx": () => import("./../../../src/pages/die-stiftung.tsx" /* webpackChunkName: "component---src-pages-die-stiftung-tsx" */),
  "component---src-pages-fischers-corner-tsx": () => import("./../../../src/pages/fischers-corner.tsx" /* webpackChunkName: "component---src-pages-fischers-corner-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-projekte-tsx": () => import("./../../../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-templates-aktuelles-tsx": () => import("./../../../src/templates/aktuelles.tsx" /* webpackChunkName: "component---src-templates-aktuelles-tsx" */),
  "component---src-templates-projekt-tsx": () => import("./../../../src/templates/projekt.tsx" /* webpackChunkName: "component---src-templates-projekt-tsx" */),
  "component---src-templates-veranstaltungen-plus-projekt-tsx": () => import("./../../../src/templates/veranstaltungen/plusProjekt.tsx" /* webpackChunkName: "component---src-templates-veranstaltungen-plus-projekt-tsx" */),
  "component---src-templates-veranstaltungen-plus-tsx": () => import("./../../../src/templates/veranstaltungen/plus.tsx" /* webpackChunkName: "component---src-templates-veranstaltungen-plus-tsx" */),
  "component---src-templates-veranstaltungen-programmbeispiel-tsx": () => import("./../../../src/templates/veranstaltungen/programmbeispiel.tsx" /* webpackChunkName: "component---src-templates-veranstaltungen-programmbeispiel-tsx" */),
  "component---src-templates-veranstaltungen-veranstaltung-tsx": () => import("./../../../src/templates/veranstaltungen/veranstaltung.tsx" /* webpackChunkName: "component---src-templates-veranstaltungen-veranstaltung-tsx" */),
  "component---src-templates-veranstaltungen-veranstaltungsuebersicht-tsx": () => import("./../../../src/templates/veranstaltungen/veranstaltungsuebersicht.tsx" /* webpackChunkName: "component---src-templates-veranstaltungen-veranstaltungsuebersicht-tsx" */)
}

